<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            to="/domains/creation"
          >
            Add Domain <feather-icon icon="Link2Icon" size="12" />
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(domain_link)="data">
            <a :href="data.value" target="_blank">{{ data.value }}</a>
          </template>
          <template #cell(script_link)="data">
            <!-- <b-avatar :src="scriptPath + data.value" /> -->
            <b-form-input
              :readonly="true"
              id="h-title"
              :value="scriptPath + data.value"
            />
            <!-- v-model="data.item.url" -->
          </template>
          <template #cell(action)="data">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-dark"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Copy"
                v-clipboard:copy="scriptPath + data.item.script_link"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <feather-icon icon="ClipboardIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="flat-info"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Update"
                @click="editDomain(data.item.id)"
              >
                <feather-icon icon="EditIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Delete"
                @click="confirmDeleteDomain(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Preview"
                style="color: #746af8 !important"
                @click="previewTemplateOfCampaign(data.item.template)"
              >
                <feather-icon icon="EyeIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Download"
                style="color: #8dc245 !important"
                @click="downloadIndexHTML(data.item.basehtml.base_html)"
              >
                <feather-icon icon="DownloadIcon" size="20" />
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
      <b-modal
        id="modal-danger"
        ok-variant="danger"
        ok-title="Confirm"
        modal-class="modal-danger"
        centered
        title="Deleting Domain!"
        @ok="deleteDomain"
      >
        <b-card-text>
          Are you sure you want to delete this domain?
        </b-card-text>
      </b-modal>
      <b-col cols="6">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-25"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { saveAs } from "file-saver";
var FileSaver = require("file-saver");
import {
  BTable,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import store from "@/store";
import router from "@/router";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";

export default {
  components: {
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BCardText,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      path: this.$ImagesURL,
      scriptPath: this.$ScriptsURL,
      _id: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "domain_name",
          label: "Domain Name",
        },
        {
          key: "domain_link",
          label: "Domain Link",
        },
        {
          key: "script_link",
          label: "Script Link",
        },
        "Action",
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.showAllDomains();
  },
  created() {},
  methods: {
    downloadIndexHTML(baseHTML) {
      var blob = new Blob([baseHTML], {
        type: "text/plain;charset=utf-8",
      });
      FileSaver.saveAs(blob, "index.html");
    },
    previewTemplateOfCampaign(templateContent) {
      localStorage.setItem("templateContent", JSON.stringify(templateContent));
      let routeData = router.resolve({
        name: "editor-preview-content",
      });
      
      window.open(routeData.href, "_blank");
      
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Script link copied",
          icon: "ClipboardIcon",
          variant: "dark",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy the script link!",
          icon: "ClipboardIcon",
        },
      });
    },
    confirmDeleteDomain(id) {
      this._id = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    deleteDomain() {
      axios
        .post("/delete-domain", {
          id: this._id,
        })
        .then(({ data }) => {
          if (data.status === "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status === "success") {
            this.showToast("success", "TrashIcon", "Done", data.message);
            this.showAllDomains();
          } else if (data.status === "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status === "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editDomain: (id) => {
      store
        .dispatch("appsManager/setDomainId", id)
        .then((response) => {
          router.push({ name: "domains-edit" });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    showAllDomains() {
      this.$store
        .dispatch("appsManager/getAllDomains")
        .then(({ data }) => {
          this.items = data[0];
          this.totalRows = this.items.length;
        })
        .catch(console.error);
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>